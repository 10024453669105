// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aportaciones-tsx": () => import("./../../../src/pages/aportaciones.tsx" /* webpackChunkName: "component---src-pages-aportaciones-tsx" */),
  "component---src-pages-concurso-tsx": () => import("./../../../src/pages/concurso.tsx" /* webpackChunkName: "component---src-pages-concurso-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mensaje-tsx": () => import("./../../../src/pages/mensaje.tsx" /* webpackChunkName: "component---src-pages-mensaje-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-quien-fue-tsx": () => import("./../../../src/pages/quienFue.tsx" /* webpackChunkName: "component---src-pages-quien-fue-tsx" */),
  "component---src-pages-registro-tsx": () => import("./../../../src/pages/registro.tsx" /* webpackChunkName: "component---src-pages-registro-tsx" */)
}

