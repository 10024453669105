/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import $ from 'jquery' // important: case sensitive.
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider useRecaptchaNet reCaptchaKey="6LcYnpkaAAAAAMu0uTl7OyCCYgBKGYoLNpUdc5eR">
            {element}
        </GoogleReCaptchaProvider>
    )
}

export const onInitialClientRender = () => {
    console.log("onInitialClientRender");
    $(function () {
        setTimeout(function(){
            $(".preloader").fadeOut();
            console.log("LOADED 4");
          },500);
        }
    );
}
export const onClientEntry = () => {
    console.log("onClientEntry")
    window.onload = () => { console.log("new test") }
  }

  export const onPostPrefetchPathname = () => {
     console.log("onPostPrefetchPathname") 
     setTimeout(function(){
        $(".preloader").fadeOut();
        console.log("LOADED 4");
      },500);
  }

  export const onPreRouteUpdate = ({ location, prevLocation }) => {
    console.log("Gatsby started to change location to", location.pathname)
    console.log("Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)
    setTimeout(function(){
        $(".preloader").fadeOut();
        console.log("LOADED 4");
      },500);
  }